<template>
  <v-container fluid class="recipe-list-container">
    <!-- TODO - BREADCRUMB  -->
    <v-row>
      <v-col cols="12" md="9">
        <h1>{{ $t("recipe.title") }}</h1>
        <RecipeTree></RecipeTree>
        <div v-if="content != null">
          <h2>{{ $t("recipe.subtitle") }}</h2>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="4"
              xl="4"
              v-for="recipe in content"
              :key="recipe.id"
            >
              <RecipeCard :recipe="recipe"></RecipeCard>
            </v-col>
          </v-row>
        </div>
        <div v-if="noRecipeToShow">
          {{ $t("recipe.noRecipeToShow") }}
        </div>
        <v-pagination
          class="mt-12"
          color="primary"
          v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
          :value="pageFilter"
          :page="pager.selPage"
          :length="pager.totPages ? pager.totPages : 0"
          :totalVisible="6"
          @next.stop.prevent="handlePageFilter"
          @prev="handlePageFilter"
          @input="handlePageFilter"
        ></v-pagination>
      </v-col>
      <v-col cols="12" md="3">
        <PostSidebar :isRecipe="true"></PostSidebar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <PostFooter></PostFooter>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.recipe-list-container {
  .recipe-card-link {
    text-decoration: none !important;
  }
  .recipe-img {
    cursor: pointer;
  }
  .share-btn {
    cursor: pointer;
  }
  a[class^="share-network"] {
    text-decoration: none;
  }
  .recipe-course {
    font-size: 1rem !important;
  }
  .recipe-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 60px;
  }
  .recipe-card.v-card {
    display: flex;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      flex-direction: column;
      .v-card__title {
        height: auto !important;
      }
    }
    .recipe-image {
      border-radius: 4px 0 0 4px;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        flex-direction: column;
        border-radius: 4px 4px 0 0;
      }
      @media #{map-get($display-breakpoints, 'sm-only')} {
        border-radius: 4px 4px 0 0;
        width: auto;
        max-width: auto;
        height: 240px;
      }
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        max-width: 40%;
        width: 40%;
      }
    }
    .v-card__title {
      font-weight: bold;
      font-size: 20px;
      color: $primary !important;
      word-break: normal;
      max-height: 56px;
      height: 56px;
      overflow: hidden;
      padding: 0px;
      margin: 16px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      /* autoprefixer: off */
    }
    .v-card__text {
      align-items: flex-start;
      font-size: 1rem;
      word-break: break-word;
      max-height: 78px;
      height: 78px;
      overflow: hidden;
      width: 91%;
      padding: 0px;
      margin: 0px 16px 16px 16px;
      .description {
        overflow: hidden;
        height: 91px;
        max-height: 91px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        line-height: 1.34;
        /* autoprefixer: off */
      }
    }
    .characteristics {
      font-size: 12px;
      line-height: 2;
      margin: 0px 16px;
      .meal-course {
        color: white;
        background-color: $primary;
        border-radius: 10px;
      }
    }
  }
}
</style>
<script>
import Vue from "vue";
import categoryMixins from "~/mixins/category";
import clickHandler from "~/mixins/clickHandler";
import RecipeTree from "@/components/wordpress/RecipeTree.vue";
import CMService from "~/service/cmService";
import socialSharing from "vue-social-sharing";
import RecipeCard from "@/components/wordpress/RecipeCard.vue";
import PostSidebar from "@/components/wordpress/PostSidebar.vue";
import PostFooter from "@/components/wordpress/PostFooter.vue";
import { mapGetters } from "vuex";

import get from "lodash/get";

export default {
  name: "RecipeList",
  components: {
    RecipeTree,
    RecipeCard,
    PostSidebar,
    PostFooter
  },
  mixins: [categoryMixins, clickHandler],
  props: {
    s: {
      type: String,
      default: null
    },
    course: {
      type: String,
      default: null
    },
    ingredients: {
      type: String,
      default: null
    },
    cuisine: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      content: null,
      newFilters: false,
      noRecipeToShow: false,
      pager: {}
    };
  },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    }),
    rating() {
      return get(this.pageData, "rating");
    }
  },
  methods: {
    async fetchPage(pageNum) {
      let params = [];
      this.page = pageNum || this.pageFilter;
      console.log("pageParams", this.page);
      this.pager.selPage = this.page;
      params.push({
        filterName: "page",
        filterValue: this.page
      });
      if (this.s != null && this.s.length > 0) {
        params.push({
          filterName: "filter[s]",
          filterValue: this.s
        });
      }
      if (this.course != null && this.course.length > 0) {
        params.push({
          filterName: "filter[course]",
          filterValue: this.course
        });
      }
      if (this.ingredients != null && this.ingredients.length > 0) {
        params.push({
          filterName: "filter[ingredient]",
          filterValue: this.ingredients
        });
      }
      if (this.cuisine != null && this.cuisine.length > 0) {
        params.push({
          filterName: "filter[cuisine]",
          filterValue: this.cuisine
        });
      }
      const res = await CMService.getCustomPostByFilters("recipe", params, 12);

      if (res && res.data && res.data.length) {
        this.pager = res.page;
        this.content = res.data;
        this.noRecipeToShow = false;
      } else {
        this.content = null;
        this.noRecipeToShow = true;
      }
      console.log(res);
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      this.fetchPage(page);
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    },
    doPrint() {
      window.print();
    }
  },
  mounted() {
    Vue.use(socialSharing);
    this.fetchPage();
  },
  watch: {
    s() {
      let vm = this;
      if (this.newFilters == false) {
        this.newFilters = true;
        setTimeout(function() {
          vm.newFilters = false;
          vm.fetchPage(1);
        }, 300);
      }
    },
    cuisine() {
      let vm = this;
      // if (this.cuisine != null && this.newFilters == false) {
      if (this.newFilters == false) {
        this.newFilters = true;
        setTimeout(function() {
          vm.newFilters = false;
          vm.fetchPage(1);
        }, 300);
      }
    },
    ingredients() {
      let vm = this;
      if (this.newFilters == false) {
        this.newFilters = true;
        setTimeout(function() {
          vm.newFilters = false;
          vm.fetchPage(1);
        }, 300);
      }
    },
    course() {
      let vm = this;
      // if (this.course != null && this.newFilters == false) {
      if (this.newFilters == false) {
        this.newFilters = true;
        setTimeout(function() {
          vm.newFilters = false;
          vm.fetchPage(1);
        }, 300);
      }
    }
  },
  jsonld() {
    if (this.page) {
      return {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": this.page.link
        },
        headline: this.page.meta ? this.page.meta.seo_title : "",
        image: ["/logo/social-logo.png"],
        author: {
          "@type": "Organization",
          name: global.config?.defaultPageName
        },
        datePublished: this.page.date,
        dateModified: this.page.date,
        publisher: {
          "@type": "Organization",
          name: global.config?.defaultPageName,
          logo: {
            "@type": "ImageObject",
            url: "/logo/social-logo.png"
          }
        }
      };
    }
  },
  metaInfo() {
    return {
      title: "Ricette",
      link: [
        {
          rel: "canonical",
          href: `https://${window.location.host}${this.$route.path}`
        }
      ],
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.page, "meta.seo_description")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(
            this.page,
            "meta.seo_title",
            global.config?.defaultPageName
          )
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          property: "og:image:alt",
          content: get(this.page, "meta.seo_description")
        },
        {
          property: "og:site_name",
          content: global.config?.defaultPageName
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(this.page, "meta.seo_title", "Pagina statica")
        },
        {
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
