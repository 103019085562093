<template>
  <div>
    <div class="recipe-search white mb-7">
      <v-chip
        label
        link
        class="mt-6 mr-2 mb-2 rounded-lg"
        :to="{ name: 'RecipeList' }"
        >TUTTE</v-chip
      >
      <v-chip
        v-for="course in coursesData"
        label
        link
        :key="course.id"
        @click="filterByCourse(course)"
        class="mt-6 mr-2 mb-2 rounded-lg"
      >
        {{ course.name }}
      </v-chip>
    </div>
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import CMSService from "~/service/cmService";
export default {
  name: "RecipeTree",
  data() {
    return {
      course: null,
      coursesData: null
    };
  },
  props: {
    changePage: { type: String, default: "true" }
  },
  methods: {
    async fetchWpDataCourse() {
      const result = await CMSService.getCustomPostBySlug("course");
      if (result && result.length) {
        this.coursesData = result;
      }
    },
    filterByCourse(course) {
      let query = {};
      query["course"] = course.slug;
      if (this.changePage == "true") {
        this.$router.push({ name: "RecipeList", query });
      } else {
        this.$router.replace({ query });
      }
    }
  },
  created() {
    this.coursesData = this.fetchWpDataCourse();
  }
};
</script>
